export const shortenString = (inputString, maxLength) => {
  if (inputString?.length <= maxLength) {
    return inputString;
  }

  const firstPartLength = Math.floor((maxLength - 3) / 2);
  const secondPartLength = maxLength - 3 - firstPartLength;

  const firstPart = inputString?.substring(0, firstPartLength);
  const secondPart = inputString?.substring(
    inputString?.length - secondPartLength
  );

  return `${firstPart}...${secondPart}`;
};
