import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import "./lastestBlockModule.scss";
import {
  ChangeNumBalance,
  formatMoney,
} from "../../../../ulti/changeNumbalance";

import request from "../../../../ulti/request";
import { shortenString } from "../../../../ulti/shortString";
import ex from "../../../../assets/icons/ex.png";

function LastesBlock() {
  const [block, setBlock] = useState([]);
  const getApiSummary = () => {
    request
      .get(
        "ext/getlasttxsajax/0?draw=1&columns%5B0%5D%5Bdata%5D=0&columns%5B0%5D%5Bname%5D=&columns%5B0%5D%5Bsearchable%5D=true&columns%5B0%5D%5Borderable%5D=false&columns%5B0%5D%5Bsearch%5D%5Bvalue%5D=&columns%5B0%5D%5Bsearch%5D%5Bregex%5D=false&columns%5B1%5D%5Bdata%5D=1&columns%5B1%5D%5Bname%5D=&columns%5B1%5D%5Bsearchable%5D=true&columns%5B1%5D%5Borderable%5D=false&columns%5B1%5D%5Bsearch%5D%5Bvalue%5D=&columns%5B1%5D%5Bsearch%5D%5Bregex%5D=false&columns%5B2%5D%5Bdata%5D=2&columns%5B2%5D%5Bname%5D=&columns%5B2%5D%5Bsearchable%5D=true&columns%5B2%5D%5Borderable%5D=false&columns%5B2%5D%5Bsearch%5D%5Bvalue%5D=&columns%5B2%5D%5Bsearch%5D%5Bregex%5D=false&columns%5B3%5D%5Bdata%5D=3&columns%5B3%5D%5Bname%5D=&columns%5B3%5D%5Bsearchable%5D=true&columns%5B3%5D%5Borderable%5D=false&columns%5B3%5D%5Bsearch%5D%5Bvalue%5D=&columns%5B3%5D%5Bsearch%5D%5Bregex%5D=false&columns%5B4%5D%5Bdata%5D=4&columns%5B4%5D%5Bname%5D=&columns%5B4%5D%5Bsearchable%5D=true&columns%5B4%5D%5Borderable%5D=false&columns%5B4%5D%5Bsearch%5D%5Bvalue%5D=&columns%5B4%5D%5Bsearch%5D%5Bregex%5D=false&start=0&length=10&search%5Bvalue%5D=&search%5Bregex%5D=false&_=1689760780738"
      )
      .then((res) => {
        setBlock(res.data.data);
      })
      .catch((res) => {
        console.log(res);
      });
  };

  useEffect(() => {
    getApiSummary();
    const interval = setInterval(() => {
      getApiSummary();
    }, 60000);
    return () => {
      clearInterval(interval);
    };
  }, []);

  // console.log('block:', block)

  // console.log('search', search)

  return (
    <div className="lastesblock">
      <h1>Latest Block</h1>
      <div className="lastesblock-table">
        <table>
          <thead>
            <tr>
              <th>Block</th>
              <th>Hash</th>
              <th>Recipients</th>
              <th>
                Amount <span style={{ fontSize: "11px" }}>(NOW)</span>
              </th>
              <th>Timestamp</th>
            </tr>
          </thead>
          <tbody>
            {block?.map((item, index) => {
              const blockcolumn = item[0];
              const tx = item[1];
              const hashcolumn = item[2];
              const recipientscolumn = item[3];
              const timestampcolumn = item[5];
              return (
                <tr key={index}>
                  <td
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "10px",
                    }}
                  >
                    <div>
                      <img src={ex} alt=""></img>
                    </div>
                    <span>
                      <Link to={`block/${tx}`}>{blockcolumn}</Link>
                    </span>
                  </td>
                  <td>
                    <p>
                      <Link to={`tx/${hashcolumn}`}>
                        {shortenString(hashcolumn, 30)}
                      </Link>
                    </p>
                  </td>
                  <td>
                    <p>{recipientscolumn}</p>
                  </td>
                  <td>
                    <p>{formatMoney(item[4])}</p>
                  </td>
                  <td>
                    <p>{timestampcolumn}</p>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>

      <a className="btn" href="/allblock/">
        <p className="btn-all">View all block</p>
      </a>
    </div>
  );
}

export default LastesBlock;
