import React from "react";
import LastesBlock from "../../Component/Layouts/Body/LastestBlock";
import LastestTransaction from "../../Component/Layouts/Body/LastestTransaction";
import "./swbModule.scss";
import InfoCoin from "../../Component/Layouts/Body/InforCoin";
import LogoNow from "../../assets/icons/NowCoin.png";

function SWB() {
  const coin = [
    {
      id: "1",
      logo: LogoNow,
      name: "NOW",
      price: "$0.0085",
      marketPrice: "$9,233,345.00",
      transactions: "34,234",
      lastestBlock: "13234",
      moreInfoCoin: {
        network: "37.237",
        difficulty: "0.00021",
        coinSupply: "33,443",
      },
    },
  ];
  return (
    <div className="">
      <InfoCoin coin={coin} />
      <div className="body">
        <div className="list">
          <LastesBlock />
          <LastestTransaction />
        </div>
      </div>
    </div>
  );
}

export default SWB;
