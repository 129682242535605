import React from "react";

import "./Api.Module.scss";

function Api() {
  const apicoind = [
    {
      title: "getdifficulty",
      desc: "Returns the current difficulty.",
      url: "testnet.nowscan.io/api/getdifficulty",
      link: "https://testnet.nowscan.io/api/getdifficulty",
    },
    {
      title: "getconnectioncount",
      desc: "Returns the number of connections the block explorer has to other nodes.",
      url: "testnet.nowscan.io/api/getconnectioncount",
      link: "https://testnet.nowscan.io/api/getconnectioncount",
    },
    {
      title: "getblockcount",
      desc: "Returns the current block index.",
      url: "testnet.nowscan.io/api/getblockcount",
      link: "https://testnet.nowscan.io/api/getblockcount",
    },
    {
      title: "getblockhash [index]",
      desc: "Returns the hash of the block at ; index 0 is the genesis block.",
      url: "testnet.nowscan.io/api/getblockhash?index=2110",
      link: "https://testnet.nowscan.io/api/getblockhash?index=2110",
    },
    {
      title: "getblock [hash]",
      desc: "Returns information about the block with the given hash.            ",
      url: "testnet.nowscan.io/api/getblock?hash=c925d7e6c4cdc186574580ba6f9988769fab4e0b5ea1583adf037effbdedc35b",
      link: "https://testnet.nowscan.io/api/getblock?hash=c925d7e6c4cdc186574580ba6f9988769fab4e0b5ea1583adf037effbdedc35b",
    },
    {
      title: "getrawtransaction [txid] [decrypt]",
      desc: "Returns raw transaction representation for given transaction id. decrypt can be set to 0(false) or 1(true).",
      url: "testnet.nowscan.io/api/getrawtransaction?txid=3efdccbedd5553173504cf00f0d52557bc0b563063e85d4a8ffd92b06959e774&decrypt=0",
      link: "https://testnet.nowscan.io/api/getrawtransaction?txid=3efdccbedd5553173504cf00f0d52557bc0b563063e85d4a8ffd92b06959e774&decrypt=0",
    },
    {
      title: "getnetworkhashp",
      desc: "Returns the current network hashrate. (hash/s)",

      url: "testnet.nowscan.io/api/getnetworkhashps",
      link: "https://testnet.nowscan.io/api/getnetworkhashps",
    },
  ];

  const apilocal = [
    {
      title: "getmoneysupply            ",
      desc: "Returns current money supply            ",
      url: "testnet.nowscan.io/ext/getmoneysupply            ",
      link: "https://testnet.nowscan.io/ext/getmoneysupply",
    },
    {
      title: "getdistribution            ",
      desc: "Returns wealth distribution stats            ",
      url: "testnet.nowscan.io/ext/getaddress/NXXZASoTsJttwcUs9tVHhvaxTPTiiDhatR            ",
      link: "https://testnet.nowscan.io/ext/getdistribution",
    },
    {
      title: "getaddress (/ext/getaddress/hash)            ",
      desc: "Returns information for given tx hash            ",
      url: "testnet.nowscan.io/ext/gettx/3efdccbedd5553173504cf00f0d52557bc0b563063e85d4a8ffd92b06959e774           ",
      link: "https://testnet.nowscan.io/ext/getaddress/NXXZASoTsJttwcUs9tVHhvaxTPTiiDhatR",
    },
    {
      title: "gettx (/ext/gettx/hash)            ",
      desc: "Returns information for given tx hash            ",
      url: "testnet.nowscan.io/ext/gettx/3efdccbedd5553173504cf00f0d52557bc0b563063e85d4a8ffd92b06959e774            ",
      link: "https://testnet.nowscan.io/ext/gettx/3efdccbedd5553173504cf00f0d52557bc0b563063e85d4a8ffd92b06959e774",
    },
    {
      title: "getbalance (/ext/getbalance/hash)            ",
      desc: "Returns current balance of given address           ",
      url: "testnet.nowscan.io/ext/getbalance/NXXZASoTsJttwcUs9tVHhvaxTPTiiDhatR            ",
      link: "https://testnet.nowscan.io/ext/getbalance/NXXZASoTsJttwcUs9tVHhvaxTPTiiDhatR",
    },
    {
      title: "getlasttxsajax (/ext/getlasttxsajax/min)            ",
      desc: "Returns last transactions greater than [min]          ",

      url: "testnet.nowscan.io/ext/getlasttxsajax/100           ",
      link: "https://testnet.nowscan.io/ext/getlasttxsajax/100",
    },
  ];

  const apiexplorer = [
    {
      title: "transaction (/tx/txid)",
      url: "/tx/3efdccbedd5553173504cf00f0d52557bc0b563063e85d4a8ffd92b06959e774",
      link: "/tx/3efdccbedd5553173504cf00f0d52557bc0b563063e85d4a8ffd92b06959e774",
    },
    {
      title: "block (/block/hash)",
      url: "/block/c925d7e6c4cdc186574580ba6f9988769fab4e0b5ea1583adf037effbdedc35b",
      link: "/block/c925d7e6c4cdc186574580ba6f9988769fab4e0b5ea1583adf037effbdedc35b",
    },
    {
      title: "address (/address/hash)",
      url: "/address/NXXZASoTsJttwcUs9tVHhvaxTPTiiDhatR",
      link: "/address/NXXZASoTsJttwcUs9tVHhvaxTPTiiDhatR",
    },

    {
      title: "qrcode (/qr/hash)",
      url: "/qr/NXXZASoTsJttwcUs9tVHhvaxTPTiiDhatR",
      link: "https://testnet.nowscan.io/qr/NXXZASoTsJttwcUs9tVHhvaxTPTiiDhatR",
    },
  ];
  return (
    <div className="api-containner">
      <div>
        <h5>API Documentation</h5>
        <p>
          The block explorer provides an API allowing users and/or applications
          to retrieve information from the network without the need for a local
          wallet.
        </p>
      </div>
      <div>
        <h1>API Calls</h1>
        <p>Return data from coind</p>
        <ul>
          {apicoind?.map((api, index) => {
            return (
              <li key={index}>
                <p>{api.title}</p>
                <p>{api.desc}</p>
                <a href={api.link}>{api.url}</a>
              </li>
            );
          })}
        </ul>
      </div>
      <div>
        <h1>Extended API</h1>
        <p>Return data from local indexes</p>
        <ul>
          {apilocal?.map((api, index) => {
            return (
              <li key={index}>
                <p>{api.title}</p>
                <p>{api.desc}</p>
                <a href={api.link}>{api.url}</a>
              </li>
            );
          })}
        </ul>
      </div>
      <div>
        <h1>Linking (GET)</h1>
        <p>Linking to the block explorer</p>
        <ul>
          {apiexplorer?.map((api, index) => {
            return (
              <li key={index}>
                <p>{api.title}</p>
                <p>{api.desc}</p>
                <a href={api.link}>{api.url}</a>
              </li>
            );
          })}
        </ul>
      </div>
    </div>
  );
}

export default Api;
