// const convertTimestampToDate=(timestamp) =>{
//     const date = new Date(timestamp * 1000);
//     return date.toLocaleString(); // Use toLocaleString() to format the date and time
// }

function convertTimestampToDate(hexTimestamp) {
    // Chuyển hex timestamp về dạng số hệ 10
    const unixTimestamp = parseInt(hexTimestamp, 10);
  
    // Kiểm tra xem có phải là số hợp lệ hay không
    if (isNaN(unixTimestamp)) {
      return "Invalid timestamp";
    }
  
    // Tạo đối tượng Date từ Unix timestamp
    const dateObject = new Date(unixTimestamp * 1000); // Phải nhân 1000 vì Date nhận tham số là số milisecond
  
    // Trả về chuỗi ngày tháng
    return dateObject.toUTCString();
  }
  
  // Ví dụ
//   const hexTimestamp = '0x63ad15fd'; // Đổi số này thành Unix hex timestamp bạn muốn
//   const date = unixHexTimestampToDate(hexTimestamp);
//   console.log(date); // Kết quả: "Tue, 11 Jan 2023 07:06:37 GMT"

export {convertTimestampToDate}