import React from "react";
import "./NotFound.scss";
import { Link } from "react-router-dom";
function NotFound() {
  return (
    <div className="notfound-container">
      <p>404 Page Not Found</p>
      <Link to="/">Back to Home</Link>
    </div>
  );
}

export default NotFound;
