import React from "react";

const Received = ({data}) =>{
    return (
        <div>
            <h2>Top 100 - Received Coins</h2>
                <table>
                    <thead>

                        <td></td>
                        <td>Address</td>
                        <td>Received (NOW)</td>
                        
                    </thead>
                    
                    <tbody>
                        {data.map((item,index)=>{
                                var total = 0 
                                total += item.balance
                                return(
                                    <tr>
                                        <td>{index + 1}</td>
                                        <td><a href={`/address/${item.a_id}`}>{item.a_id}</a></td>
                                        <td>{item.balance  / 100000000}</td>
                                    </tr>
                                )
                            })}
                    </tbody>
                    
                </table>
            </div>
        
    )
}

export default Received