import React from 'react'
import ReactPaginate from 'react-paginate';

function Page(props) {
    return (
        
            <ReactPaginate
                previousLabel="Previous"
                nextLabel="Next"
                pageClassName="page-item"
                pageLinkClassName="page-link"
                previousClassName="page-item"
                previousLinkClassName="page-link"
                nextClassName="page-item"
                nextLinkClassName="page-link"
                breakLabel="..."
                breakClassName="page-item"
                breakLinkClassName="page-link"
                pageCount={props.total}
                marginPagesDisplayed={1}
                pageRangeDisplayed={2}
                onPageChange={props.handlePageChange}
                containerClassName="pagination"
                activeClassName="active"
                renderOnZeroPageCount={null}
            />
        
    )
}

export default Page