function formatCash(str) {
  return str
    .split("")
    .reverse()
    .reduce((prev, next, index) => {
      return (index % 3 ? next : next) + prev;
    });
}

const ChangeNumBalance = (number) => {
  const amount = number / 100000000;
  const amountstr = amount.toString();
  const result = formatCash(amountstr);
  return result;
};

export { ChangeNumBalance };

export const formatMoney = (amount) => {
  const formatter = new Intl.NumberFormat("en-US", {
    minimumFractionDigits: 0,
    maximumFractionDigits: 4,
  });

  return formatter.format(amount);
};
