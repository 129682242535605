import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import "./AddressModule.scss";

import { ChangeNumBalance, formatMoney } from "../../ulti/changeNumbalance";

import Page from "../../Component/Page";
import { ChangeNum } from "../../ulti/changeNumber";
import request from "../../ulti/request";
function Address() {
  const txId = useParams();
  const [address, setAddress] = useState([]);
  const [balance, setBalance] = useState([]);
  const [selectNumber, SetSelectNumber] = useState(10);
  const [totalEn, setTotalEn] = useState([]);
  const [draw, setDraw] = useState(1);
  const [pageNumber, setPageNumber] = useState(0);

  // Lấy thời gian hiện tại
  const currentTime = new Date();

  // Chuyển đổi thành timestamp (Unix timestamp)
  const timestamp = Math.floor(currentTime.getTime() / 1000);
  const [timecurrent, setTimeCurrent] = useState(timestamp);
  const [start, setStart] = useState(0);
  // Hàm xử lý sự kiện khi người dùng chuyển trang
  // const handlePageChange = (current, startPage) => {
  //     // setCurrentPage(currentPage + 1);
  //     setDraw(draw + 1)
  //     setCurrentPage(current)

  //     setTimeCurrent(timecurrent + 1)
  //     setStart(startPage)
  // };

  // Xử lý sự kiện thay đổi số lượng
  const handleQuantityChange = (event) => {
    const newQuantity = parseInt(event.target.value, 10);
    SetSelectNumber(newQuantity);
    // setCurrentPage(1)
    // setStart(0)
  };

  const getApiAddress = (page) => {
    request
      .get(
        `ext/getaddresstxsajax/${txId.address}?draw=${draw}&columns%5B0%5D%5Bdata%5D=0&columns%5B0%5D%5Bname%5D=&columns%5B0%5D%5Bsearchable%5D=true&columns%5B0%5D%5Borderable%5D=false&columns%5B0%5D%5Bsearch%5D%5Bvalue%5D=&columns%5B0%5D%5Bsearch%5D%5Bregex%5D=false&columns%5B1%5D%5Bdata%5D=1&columns%5B1%5D%5Bname%5D=&columns%5B1%5D%5Bsearchable%5D=true&columns%5B1%5D%5Borderable%5D=false&columns%5B1%5D%5Bsearch%5D%5Bvalue%5D=&columns%5B1%5D%5Bsearch%5D%5Bregex%5D=false&columns%5B2%5D%5Bdata%5D=2&columns%5B2%5D%5Bname%5D=&columns%5B2%5D%5Bsearchable%5D=true&columns%5B2%5D%5Borderable%5D=false&columns%5B2%5D%5Bsearch%5D%5Bvalue%5D=&columns%5B2%5D%5Bsearch%5D%5Bregex%5D=false&columns%5B3%5D%5Bdata%5D=3&columns%5B3%5D%5Bname%5D=&columns%5B3%5D%5Bsearchable%5D=true&columns%5B3%5D%5Borderable%5D=false&columns%5B3%5D%5Bsearch%5D%5Bvalue%5D=&columns%5B3%5D%5Bsearch%5D%5Bregex%5D=false&start=${page}&length=${selectNumber}&search%5Bvalue%5D=&search%5Bregex%5D=false&_=${timecurrent}`
      )
      .then((res) => {
        const result = res.data.data;
        const total = res.data;
        setAddress(result);
        setTotalEn(total);
      })
      .catch((res) => {
        console.log(res);
      });
  };

  const getApiBalance = () => {
    request
      .get(`ext/getaddress/${txId.address}`)
      .then((res) => {
        const result = res.data;
        setBalance(result);
      })
      .catch((res) => {
        console.log(res);
      });
  };

  const handlePageChange = (page) => {
    getApiAddress(page.selected * selectNumber);
    setDraw(draw + 1);
    setPageNumber(page.selected);
    setStart(start + selectNumber);
  };

  // const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - totalEn.length) : 0;

  // const handleChangePage = (event, newPage) => {
  //     setPage(newPage);
  // };

  // const handleChangeRowsPerPage = (event) => {
  //     const newQuantity = parseInt(event.target.value, 10);
  //     setRowsPerPage(newQuantity);
  //     SetSelectNumber(newQuantity);
  //     setPage(0)
  // };

  const balaceArray = [balance];
  //total entries
  const totalEnArray = [totalEn];

  // const pagesToShow = Array.from({ length: totalEn / selectNumber }, (_, index) => index + 1).slice(Math.max(0, currentPage - 3), Math.min(totalEn.length, currentPage + 3));

  useEffect(() => {
    getApiAddress();
    getApiBalance();
  }, [selectNumber, timecurrent]);

  return (
    <div className="address-container">
      <div className="address-info">
        <h4>{txId.address}</h4>
        {balaceArray?.map((item, index) => {
          // const getReceived = item.received / 1000;
          // const receivedString = getReceived.toString();
          // const received = receivedString.replace(".", ",");

          return (
            <>
              <table>
                <thead>
                  <tr>
                    <th>Total Sent (NOW)</th>
                    <th>Total Received (NOW)</th>
                    <th>Balance (NOW)</th>
                    <th>QR Code</th>
                  </tr>
                </thead>
                {item.length !== 0 && (
                  <tbody>
                    <tr key={index}>
                      <td>
                        <p>{item.sent ? formatMoney(item.sent) : 0}</p>
                      </td>
                      <td>
                        <p>{item.received ? formatMoney(item.received) : 0}</p>
                        {/* {replaceNum(item.received)} */}
                      </td>
                      <td>
                        <p>{item.balance ? formatMoney(item.balance) : 0}</p>
                      </td>
                      <td>
                        <img
                          src={`https://testnet.nowscan.io/qr/${txId.address}`}
                          alt=""
                        ></img>
                      </td>
                    </tr>
                  </tbody>
                )}
              </table>
              {item.length === 0 && <div className="loading">Loading...</div>}
            </>
          );
        })}
      </div>
      <div className="address-trans">
        <h4>Latest transactions</h4>
        <div>
          <p>Show</p>
          <select value={selectNumber} onChange={handleQuantityChange}>
            <option value="10">10</option>
            <option value="25">25</option>
            <option value="50">50</option>
            <option value="75">75</option>
            <option value="100">100</option>
          </select>
          <p>Entries</p>
        </div>
        <table>
          <thead>
            <tr>
              <td>Timestamp</td>
              <td>Hash</td>
              <td>Amount (NOW)</td>
              <td>Balance (NOW)</td>
            </tr>
          </thead>
          <tbody>
            {address?.map((item, index) => {
              if (item.length === 0) {
                return <p>loading...</p>;
              } else {
                const timestamp = item[0];
                const hash = item[1];
                const amount = item[2] - item[3];
                const balance = item[4];
                return (
                  <tr>
                    <td>{timestamp}</td>
                    <td>
                      <a href={`/tx/${hash}`}>{hash}</a>
                    </td>
                    <td>
                      {amount > 0
                        ? "+" + formatMoney(amount)
                        : formatMoney(amount)}
                    </td>
                    <td>{formatMoney(balance)}</td>
                  </tr>
                );
              }
            })}
          </tbody>
        </table>
        {totalEnArray?.map((item, index) => {
          if (item.length === 0) {
            return <div key={index}></div>;
          } else {
            const total = Math.ceil(item.recordsTotal / selectNumber);
            return (
              <div className="numberpage">
                {/* <p >Showing {pageNumber * selectNumber + 1} to {start} of  {item.recordsTotal}</p> */}

                <Page handlePageChange={handlePageChange} total={total}></Page>
              </div>
            );
          }
        })}
      </div>
    </div>
  );
}

export default Address;
