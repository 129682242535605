import React from "react";
import { useState, useEffect } from "react";
import request from "../../ulti/request";
import Received from "./received";
import "./Top.Module.scss";

import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Doughnut } from "react-chartjs-2";

ChartJS.register(ArcElement, Tooltip, Legend);

function Top() {
  const [top, setTop] = useState([]);
  const [topAddr, setTopAddr] = useState([]);
  const [res, setRes] = useState([]);
  const [distri, setDistri] = useState([]);
  const [percentdata, setPercentData] = useState([]);
  const [ballance, setBallance] = useState(true);
  const [received, setReceived] = useState(false);
  const [richlist, setRichlist] = useState([]);

  function handleBallance() {
    setBallance(true);
    setReceived(false);
  }
  function handleReceived() {
    setBallance(false);
    setReceived(true);
  }

  const backgroundBallance = {
    background: ballance ? "#E30370" : "#fff",
    color: ballance ? "#fff" : "#000",
  };
  const backgroundReceived = {
    background: received ? "#E30370" : "#fff",
    color: received ? "#fff" : "#000",
  };

  const arraydistri = [distri];

  const option = {
    maintainAspectRatio: false,
    responsive: true,
    legend: {
      labels: {
        usePointStyle: true,
        fontColor: "#fff",
      },
    },
  };

  useEffect(() => {
    const apiTop = () => {
      request
        .get("/richlist_test")
        .then((res) => {
          setRichlist(res.data.balance);
        })
        .catch((res) => {
          console.log(res);
        });
    };

    const distribution = () => {
      request
        .get("https://testnet.nowscan.io/ext/getdistribution")
        .then((res) => {
          setDistri(res.data);

          const extractedPercents = Object.values(res.data)
            .filter((item) => item && item.percent) // Lọc các phần tử có trường "percent"
            .map((item) => item.percent); // Trích xuất giá trị "percent"
          extractedPercents.pop();
          setPercentData({
            labels: ["Top 1-25", "Top 26-50", "Top 51-75", "Top 76-100  "],
            datasets: [
              {
                data: extractedPercents,
                backgroundColor: ["#d8544f", "#5db85b", "#428aca", "#222222"],
              },
            ],
          });
        })
        .catch((res) => {
          console.log(res);
        });
    };

    apiTop();
    distribution();
  }, []);

  return (
    <div className="container-top">
      <div className="top-info">
        <div className="cate">
          <button onClick={handleBallance} style={backgroundBallance}>
            Balance
          </button>
          <button onClick={handleReceived} style={backgroundReceived}>
            Received
          </button>
        </div>
        {ballance && (
          <>
            <h2>Top 100 -Current Balance</h2>
            <table>
              <thead>
                <td></td>
                <td>Address</td>
                <td>Balance (NOW)</td>
                <td>%</td>
              </thead>

              <tbody>
                {richlist.map((item, index) => {
                  var total = 0;
                  total += item.balance;
                  return (
                    <tr key={index}>
                      <td>{index + 1}</td>
                      <td>
                        <a href={`/address/${item.a_id}`}>{item.a_id}</a>
                      </td>
                      <td>{item.balance}</td>
                      <td>{item.balance / total / 100}</td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </>
        )}

        {received && <Received data={richlist}></Received>}
      </div>
      <div className="distribution">
        <h2>Wealth Distribution</h2>
        <table>
          <thead>
            <td></td>
            <td>Amount (NOW)</td>
            <td>%</td>
          </thead>

          {arraydistri.map((item, index) => {
            const percents = [item];
            if (item.length == 0) {
              return <div></div>;
            }
            return (
              <tbody key={index}>
                {percents.map((item, index) => {
                  const t_1_25 = item.t_1_25;
                  const t_26_50 = item.t_26_50;
                  const t_51_75 = item.t_51_75;
                  const t_76_100 = item.t_76_100;
                  const t_101plus = item.t_101plus;
                  return (
                    <>
                      <tr key={index}>
                        <td>
                          <div style={{ background: "#d8544f" }}></div>
                          Top 1-25
                        </td>
                        <td>{t_1_25.total}</td>
                        <td>{t_1_25.percent}</td>
                      </tr>
                      <tr>
                        <td>
                          <div style={{ background: "#5db85b" }}></div>
                          Top 26-50
                        </td>
                        <td>{t_26_50.total}</td>
                        <td>{t_26_50.percent}</td>
                      </tr>
                      <tr>
                        <td>
                          <div style={{ background: "#428aca" }}></div>
                          Top 51-75
                        </td>
                        <td>{t_51_75.total}</td>
                        <td>{t_51_75.percent}</td>
                      </tr>
                      <tr>
                        <td>
                          <div style={{ background: "#222222" }}></div>
                          Top 76-100
                        </td>
                        <td>{t_76_100.total}</td>
                        <td>{t_76_100.percent}</td>
                      </tr>
                      <tr>
                        <td>Total</td>
                        <td></td>
                        <td>{t_101plus.percent}</td>
                      </tr>
                    </>
                  );
                })}
              </tbody>
            );
          })}
        </table>
        {percentdata.length == 0 ? (
          "loading"
        ) : (
          <Doughnut data={percentdata}></Doughnut>
        )}
      </div>
    </div>
  );
}

export default Top;
