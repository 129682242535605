import React, { useEffect, useState } from "react";
import "./Network.Module.scss";

import Connections from "./Connections";
import request from "../../ulti/request";
import Nodes from "./Nodes";
function Network() {
  const [connect, setConnect] = useState([]);
  const [connection, setConnection] = useState(true);
  const [nodes, setNodes] = useState(false);

  function handleNetwork() {
    setConnection(true);
    setNodes(false);
  }
  function handleNodes() {
    setConnection(false);
    setNodes(true);
  }

  const backgroundNetwork = {
    background: connection ? "#E30370" : "#fff",
    color: connection ? "#fff" : "#000",
  };
  const backgroundNode = {
    background: nodes ? "#E30370" : "#fff",
    color: nodes ? "#fff" : "#000",
  };
  useEffect(() => {
    request
      .get("https://testnet.nowscan.io/ext/connections?_=1695202321974")
      .then((res) => {
        setConnect(res.data.data);
      })
      .catch((res) => {
        console.log(res);
      });
  }, []);

  return (
    <div className="network-container">
      <p>
        This is sub sample of the network based on wallets that have connected
        to this node in the last 24hours.
      </p>
      <div className="cate">
        <button onClick={handleNetwork} style={backgroundNetwork}>
          Connections
        </button>
        <button onClick={handleNodes} style={backgroundNode}>
          Add Nodes
        </button>
      </div>

      {connection && <Connections data={connect}></Connections>}

      {nodes && <Nodes></Nodes>}
    </div>
  );
}

export default Network;
