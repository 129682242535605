import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import { useParams, Link } from "react-router-dom";
import HeaderTobe from "../../Component/Layouts/Header/HeaderTobeScan";
import InfoCoin from "../../Component/Layouts/Body/InforCoin";
import Footer from "../../Component/Layouts/Footer";

import request from "../../ulti/request";
import "./HashModule.scss";
import {
  ChangeNumBalance,
  formatMoney,
  formatMoneyNowp,
} from "../../ulti/changeNumbalance";

function Hash() {
  const txHash = useParams();
  const [hashId, setHashId] = useState([]);
  const [address, setAddress] = useState([]);
  const [loading, setLoading] = useState(true);
  function convertTimestampToDate(timestamp) {
    const date = new Date(timestamp * 1000);
    return date.toLocaleString();
  }

  const getApiHashBlock = () => {
    request
      .get(`/api/getrawtransaction?txid=${txHash.txid}&decrypt=1`)
      .then((res) => {
        setHashId(res.data);
      })
      .catch((res) => {
        console.log(res);
      });
  };
  const mapArray = [hashId];
  const addressArray = [address];

  const getApiAddress = () => {
    request
      .get(`/ext/gettx/${txHash.txid}`)
      .then((res) => {
        const result = res.data;
        setAddress(result);
      })
      .catch((res) => {
        console.log(res);
      });
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        await getApiHashBlock();
        await getApiAddress();
        setLoading(false);
      } catch (error) {
        console.error(error);
      }
    };

    fetchData();
  }, []);

  return (
    <div>
      <div className="">
        <div className="body">
          {loading ? (
            <div className="loading">
              <p className="loading-content">loadding...</p>
            </div>
          ) : (
            mapArray?.map((item, index) => {
              return (
                <div className="block-container">
                  <div className="block hash" key={index}>
                    <h3 style={{ wordBreak: "break-all" }}>
                      NOW TXid:<span>{item.txid}</span>{" "}
                    </h3>
                    <table className="table-hash" key={index}>
                      <thead>
                        <tr>
                          <th>Confirmations</th>
                          <th>Block Hash</th>

                          <th>Timestamp</th>
                        </tr>
                      </thead>
                      {item.time ? (
                        <tbody>
                          <tr>
                            <td>{item.confirmations}</td>
                            <td>
                              <Link to={`/block/${item.blockhash}`}>
                                {item.blockhash}
                              </Link>
                            </td>

                            <td>{convertTimestampToDate(item.time)}</td>
                            <td>
                              <Link to={`/block/${item.blockhash}`}>
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  height="1em"
                                  viewBox="0 0 576 512"
                                  style={{ fill: "#ffffff" }}
                                >
                                  <path d="M288 32c-80.8 0-145.5 36.8-192.6 80.6C48.6 156 17.3 208 2.5 243.7c-3.3 7.9-3.3 16.7 0 24.6C17.3 304 48.6 356 95.4 399.4C142.5 443.2 207.2 480 288 480s145.5-36.8 192.6-80.6c46.8-43.5 78.1-95.4 93-131.1c3.3-7.9 3.3-16.7 0-24.6c-14.9-35.7-46.2-87.7-93-131.1C433.5 68.8 368.8 32 288 32zM144 256a144 144 0 1 1 288 0 144 144 0 1 1 -288 0zm144-64c0 35.3-28.7 64-64 64c-7.1 0-13.9-1.2-20.3-3.3c-5.5-1.8-11.9 1.6-11.7 7.4c.3 6.9 1.3 13.8 3.2 20.7c13.7 51.2 66.4 81.6 117.6 67.9s81.6-66.4 67.9-117.6c-11.1-41.5-47.8-69.4-88.6-71.1c-5.8-.2-9.2 6.1-7.4 11.7c2.1 6.4 3.3 13.2 3.3 20.3z" />
                                </svg>
                              </Link>
                            </td>
                          </tr>
                        </tbody>
                      ) : (
                        <tbody className="loading-round">
                          <span className="loader"></span>
                        </tbody>
                      )}
                    </table>
                  </div>
                  <div className="address">
                    <div className="address-input">
                      <h3>Input Address</h3>
                      <table>
                        {addressArray?.map((item) => {
                          if (item.length === 0) {
                            return <div></div>;
                          }
                          const vins = item.tx.vin;

                          if (vins && vins.length > 0) {
                            if (vins[0].addresses === "coinbase") {
                              return (
                                <>
                                  <thead>
                                    <tr>
                                      <th>Address</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    <tr className="newcoin">
                                      <td>New Coin</td>
                                    </tr>
                                  </tbody>
                                </>
                              );
                            } else {
                              return (
                                <>
                                  (
                                  <>
                                    <thead>
                                      <tr>
                                        <th>Address</th>
                                        <th>Amount</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {vins?.map((vin, index) => {
                                        return (
                                          <tr
                                            key={index}
                                            className="address-coin"
                                          >
                                            <td>
                                              <Link
                                                to={`/address/${vin.addresses}`}
                                              >
                                                {vin.addresses}
                                              </Link>
                                            </td>
                                            <td>
                                              {item?.tx?.timestamp
                                                ? formatMoney(vin.amount)
                                                : formatMoney(
                                                    vin.amount / 100000000
                                                  )}
                                            </td>
                                          </tr>
                                        );
                                      })}
                                    </tbody>
                                  </>
                                  )
                                </>
                              );
                            }
                          } else {
                            return <div></div>;
                          }
                        })}
                      </table>
                    </div>
                    <div className="address-recipients">
                      <h3>Recipients</h3>
                      <table>
                        <thead>
                          <tr>
                            <th>Address</th>
                            <th>Amount (NOW)</th>
                          </tr>
                        </thead>
                        {addressArray?.map((item, index) => {
                          if (item.length == 0) {
                            return <p key={index}>loading...</p>;
                          } else {
                            const vouts = item?.tx;
                            return (
                              <tbody>
                                {vouts?.vout?.map((vout, index) => {
                                  return (
                                    <tr>
                                      <td>
                                        <p key={index}>
                                          <Link
                                            to={`/address/${vout?.addresses}`}
                                          >
                                            {vout?.addresses}
                                          </Link>
                                        </p>
                                      </td>
                                      <td>
                                        {item?.tx?.timestamp
                                          ? formatMoney(vout.amount)
                                          : formatMoney(
                                              vout.amount / 100000000
                                            )}
                                      </td>
                                    </tr>
                                  );
                                })}
                              </tbody>
                            );
                          }
                        })}
                      </table>
                    </div>
                  </div>
                </div>
              );
            })
          )}
        </div>
      </div>
    </div>
  );
}

export default Hash;
