const routes = {
  home: "/",
  blocks: "/allblock/",
  transactions: "movement/",
  blockdesc: "/block/:txid",
  hashdesc: "/tx/:txid",
  address: "/address/:address",
  richlist: "/richlist/",
  api: "/api",
  top: "/richlist",
  network: "/network",
  top: "/richlist",
  network: "/network",
  notfound: "/notfound",
};

export default routes;
