import React from "react";

const Connections = (props)=>{
    return(
        <div className="network-table">
                <div>
                    <p>Show</p>
                    <select >
                        <option value="10">10</option>
                        <option value="25">25</option>
                        <option value="50">50</option>
                        <option value="100">100</option>
                    </select>
                    <p>Entries</p>
                </div>
                <table>
                    <thead>
                        <td>Address</td>
                        <td>Protocol</td>
                        <td>Sub-version</td>
                        <td>Country</td>
                    </thead>
                    <tbody>
                        <tr>
                            {props.data.map((item,index)=>{
                                return(
                                    <td key={index}>
                                        {item}
                                    </td>
                                )
                            })}
                        </tr>
                    </tbody>
                </table>
                {props.data.length == 0 ? (
                    <p className="no-data">No data available in table</p>
                ):""}
                
        </div>
    )
}

export default Connections;