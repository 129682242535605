import React, { useEffect, useState } from "react";
import "./LastestTransactionModule.scss";
import request from "../../../../ulti/request";
import { Link } from "react-router-dom";
import tx from "../../../../assets/icons/tx.png";
import { shortenString } from "../../../../ulti/shortString";
import {
  ChangeNumBalance,
  formatMoney,
} from "../../../../ulti/changeNumbalance";
function LastestTransaction() {
  const [trans, setTrans] = useState([]);
  const getApiTrans = () => {
    request
      .get(
        "ext/getlasttxsajax/100?draw=38&columns%5B0%5D%5Bdata%5D=0&columns%5B0%5D%5Bname%5D=&columns%5B0%5D%5Bsearchable%5D=true&columns%5B0%5D%5Borderable%5D=false&columns%5B0%5D%5Bsearch%5D%5Bvalue%5D=&columns%5B0%5D%5Bsearch%5D%5Bregex%5D=false&columns%5B1%5D%5Bdata%5D=1&columns%5B1%5D%5Bname%5D=&columns%5B1%5D%5Bsearchable%5D=true&columns%5B1%5D%5Borderable%5D=false&columns%5B1%5D%5Bsearch%5D%5Bvalue%5D=&columns%5B1%5D%5Bsearch%5D%5Bregex%5D=false&columns%5B2%5D%5Bdata%5D=2&columns%5B2%5D%5Bname%5D=&columns%5B2%5D%5Bsearchable%5D=true&columns%5B2%5D%5Borderable%5D=false&columns%5B2%5D%5Bsearch%5D%5Bvalue%5D=&columns%5B2%5D%5Bsearch%5D%5Bregex%5D=false&start=0&length=10&search%5Bvalue%5D=&search%5Bregex%5D=false&_=1689820998571"
      )
      .then((res) => {
        setTrans(res.data.data);
      })
      .catch((res) => {
        console.log(res);
      });
  };
  useEffect(() => {
    getApiTrans();
    const interval = setInterval(() => {
      getApiTrans();
    }, 60000);
    return () => {
      clearInterval(interval);
    };
  }, []);
  return (
    <div className="lastest-transaction">
      <h1>Latest Transactions</h1>
      <div className="lastest-transaction-table">
        <table>
          <thead>
            <tr>
              <th>Txid</th>
              <th>Amount</th>
              <th>TimeStamp</th>
            </tr>
          </thead>
          <tbody>
            {trans?.map((item, index) => {
              const id = item[2];
              const amountcolumn = item[4];
              const time = item[5];
              return (
                <tr key={index}>
                  <td
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "5px",
                    }}
                  >
                    <div>
                      <img src={tx} />
                    </div>
                    <span>
                      <Link to={`/tx/${id}`}>{shortenString(id, 30)}</Link>
                    </span>
                  </td>
                  <td>
                    <p>{formatMoney(amountcolumn)}</p>
                  </td>
                  <td>
                    <p>{time}</p>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      <a className="btn" href="/movement/">
        <p className="btn-all">View all transaction</p>
      </a>
    </div>
  );
}

export default LastestTransaction;
