import React, { useEffect, useState, useContext } from "react";

import "./HeaderTobeScan.scss";

import "tippy.js/dist/tippy.css"; // optional

import { GlobalContext } from "../../../../Context/GlobalState";

import "tippy.js/themes/light.css";
import request from "../../../../ulti/request";
function HeaderTobe(props) {
  const [search, setSearch, logos, menu] = useContext(GlobalContext);
  const [searchTerm, setSearchTerm] = useState("");
  const [filterOption, setFilterOption] = useState("");
  const [animation, setAnimation] = useState(false);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const backgroundStyle = {
    background: "#181b36",
  };

  const enable = {
    top: "-50px",
    height: "340px",
    padding: "140px 40px",
    transitionDuration: "0.4s",
    transitionTimingFunction: "ease-out",
  };

  const disable = {
    height: "0",
    padding: "0 40px",
    transitionDuration: "0.4s",
    transitionTimingFunction: "ease-out",
  };

  const backgroundTranspa = {
    background: "transparent",
  };
  const [scrollY, setScrollY] = useState(0);

  const handleScroll = () => {
    setScrollY(window.scrollY);
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      request
        .post(`/search_test`, {
          search: searchTerm,
        })
        .then((res) => {
          if (res.data.result == "error") {
            window.location.href = `/notfound`;
          } else {
            window.location.href = `/${res.data.result}/${res.data.hash}`;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const handleMenu = () => {
    setAnimation(!animation);
  };

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };
    window.addEventListener("resize", handleResize);
    if (windowWidth > 1109) {
      setAnimation(false);
    }
    // Thêm sự kiện lắng nghe cuộn trang khi component được mount
    window.addEventListener("scroll", handleScroll);

    // Hủy sự kiện lắng nghe khi component bị unmount
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [windowWidth]);

  return (
    <div className="container-header">
      <div className="header">
        <div
          className="menu-bar"
          style={window.scrollY >= 100 ? backgroundStyle : backgroundTranspa}
        >
          <div className="menu-bar_left">
            <a href="/">
              <img src={props.logo} alt=""></img>
            </a>
            <div className="btn-mobile">
              <button onClick={handleMenu}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  height="1em"
                  viewBox="0 0 448 512"
                  style={{ fill: "#ffffff" }}
                >
                  <path d="M0 96C0 78.3 14.3 64 32 64H416c17.7 0 32 14.3 32 32s-14.3 32-32 32H32C14.3 128 0 113.7 0 96zM0 256c0-17.7 14.3-32 32-32H416c17.7 0 32 14.3 32 32s-14.3 32-32 32H32c-17.7 0-32-14.3-32-32zM448 416c0 17.7-14.3 32-32 32H32c-17.7 0-32-14.3-32-32s14.3-32 32-32H416c17.7 0 32 14.3 32 32z" />
                </svg>
              </button>
            </div>

            <div
              className="menu-responsive"
              style={animation ? enable : disable}
            >
              <div>
                {menu?.map((item, index) => {
                  return (
                    <div key={index}>
                      <ul key={index}>
                        <li>
                          <a href={item.link} style={props.styletext}>
                            {item.title}
                          </a>
                        </li>
                      </ul>
                    </div>
                  );
                })}
              </div>
            </div>
            <div className="menu-items">
              <div>
                {/* <Tippy
                                    theme='light'
                                    placement='bottom-start'
                                    interactive
                                    maxWidth="100%"
                                    content={
                                        <div className="logotobe">
                                            <ul>

                                                {logos?.map((item, index) => {
                                                    return (
                                                        <a href=""key={index}>
                                                            <li >
                                                                <img src={item.img} alt=""></img>
                                                            </li>
                                                        </a>
                                                    )
                                                })}

                                            </ul>
                                        </div>
                                    }
                                >
                                    <div>

                                        <img src={Hambur} alt=""></img>
                                    </div>
                                </Tippy> */}
              </div>

              {menu?.map((item, index) => {
                return (
                  <div className="menu-title" key={index}>
                    <ul key={index}>
                      <li>
                        <a href={item.link} style={props.styletext}>
                          {item.title}
                        </a>
                      </li>
                    </ul>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
      <div className="banner">
        <h1>{props.title}</h1>
        <div className="filter">
          <select
            value={filterOption}
            onChange={(e) => setFilterOption(e.target.value)}
          >
            <option value="1">All Filters</option>
            <option value="2">Addresses</option>
            <option value="3">Tokens</option>
            <option value="4">Name Tags</option>
            <option value="5">Labels</option>
            <option value="6">Websites</option>
          </select>
          <input
            type="text"
            placeholder="Search by Address /Txn Hash /Block /Token /Domain Name"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            onKeyDown={handleKeyDown}
          ></input>

          <div className="background-input"></div>
        </div>
      </div>
    </div>
  );
}

export default HeaderTobe;
