import React, { useEffect, useState, useContext } from "react";
import axios from "axios";
import request from "../../ulti/request";

import { Link } from "react-router-dom";
import "./allBlockModule.scss";
// import LogoUser from '../../assets/logouser.png'
import { ChangeNum } from "../../ulti/changeNumber";

import ReactPaginate from "react-paginate";
import Page from "../../Component/Page";
import { ChangeNumBalance, formatMoney } from "../../ulti/changeNumbalance";
function AllBlock() {
  const [allBlock, setAllBlock] = useState([]);
  const [selectNumber, SetSelectNumber] = useState(10);
  const [totalEn, setTotalEn] = useState([]);
  const [pageNumber, setPageNumber] = useState(0);
  const [start, setStart] = useState(0);
  const [draw, setDraw] = useState(0);

  // Lấy thời gian hiện tại
  const currentTime = new Date();

  // Chuyển đổi thành timestamp (Unix timestamp)
  const timestamp = Math.floor(currentTime.getTime() / 1000);

  // Xử lý sự kiện thay đổi số lượng
  const handleQuantityChange = (event) => {
    const newQuantity = parseInt(event.target.value, 10);
    SetSelectNumber(newQuantity);
    setDraw(draw + 1);
  };

  const getApiAllBlock = (page) => {
    request
      .get(
        `ext/getlasttxsajax/0?draw=${draw}&columns%5B0%5D%5Bdata%5D=0&columns%5B0%5D%5Bname%5D=&columns%5B0%5D%5Bsearchable%5D=true&columns%5B0%5D%5Borderable%5D=false&columns%5B0%5D%5Bsearch%5D%5Bvalue%5D=&columns%5B0%5D%5Bsearch%5D%5Bregex%5D=false&columns%5B1%5D%5Bdata%5D=1&columns%5B1%5D%5Bname%5D=&columns%5B1%5D%5Bsearchable%5D=true&columns%5B1%5D%5Borderable%5D=false&columns%5B1%5D%5Bsearch%5D%5Bvalue%5D=&columns%5B1%5D%5Bsearch%5D%5Bregex%5D=false&columns%5B2%5D%5Bdata%5D=2&columns%5B2%5D%5Bname%5D=&columns%5B2%5D%5Bsearchable%5D=true&columns%5B2%5D%5Borderable%5D=false&columns%5B2%5D%5Bsearch%5D%5Bvalue%5D=&columns%5B2%5D%5Bsearch%5D%5Bregex%5D=false&columns%5B3%5D%5Bdata%5D=3&columns%5B3%5D%5Bname%5D=&columns%5B3%5D%5Bsearchable%5D=true&columns%5B3%5D%5Borderable%5D=false&columns%5B3%5D%5Bsearch%5D%5Bvalue%5D=&columns%5B3%5D%5Bsearch%5D%5Bregex%5D=false&columns%5B4%5D%5Bdata%5D=4&columns%5B4%5D%5Bname%5D=&columns%5B4%5D%5Bsearchable%5D=true&columns%5B4%5D%5Borderable%5D=false&columns%5B4%5D%5Bsearch%5D%5Bvalue%5D=&columns%5B4%5D%5Bsearch%5D%5Bregex%5D=false&start=${page}&length=${selectNumber}&search%5Bvalue%5D=&search%5Bregex%5D=false&_=${timestamp}`
      )
      .then((res) => {
        const result = res.data.data;
        const total = res.data;
        setAllBlock(result);
        setTotalEn(total);
      })
      .catch((res) => {
        console.log(res);
      });
  };

  const handlePageChange = (page) => {
    getApiAllBlock(page.selected * selectNumber);
    setDraw(draw + 1);
    setPageNumber(page.selected);
    setStart(start + selectNumber);
  };

  const totalEnArray = [totalEn];
  useEffect(() => {
    getApiAllBlock();
    const interval = setInterval(() => {
      getApiAllBlock();
    }, 60000); // Định kỳ cập nhật mỗi 1p

    // Hủy interval khi component bị hủy
    return () => {
      clearInterval(interval);
    };
  }, [selectNumber]);

  return (
    <div className="allblock-container">
      <div>
        <h5>Lastest Transaction</h5>
        <div className="block-table">
          <div>
            <p>Show</p>
            <select value={selectNumber} onChange={handleQuantityChange}>
              <option value="10">10</option>
              <option value="25">25</option>
              <option value="50">50</option>
              <option value="100">100</option>
            </select>
            <p>Entries</p>
          </div>
          <table>
            <thead>
              <tr>
                <th>Block</th>
                <th>Hash</th>
                <th>Recipients </th>
                <th>Amount (NOW)</th>
                <th>Timestamp</th>
              </tr>
            </thead>
            <tbody>
              {allBlock?.map((item, index) => {
                if (item.length == 0) {
                  return <div key={index}></div>;
                } else {
                  const block = item[0];
                  const bid = item[1];
                  const hash = item[2];
                  const recipients = item[3];
                  const time = item[5];
                  return (
                    <tr>
                      <td>
                        <Link to={`/block/${bid}`}>{block}</Link>
                      </td>
                      <td>
                        <Link to={`/tx/${hash}`}>{hash}</Link>
                      </td>
                      <td>{recipients}</td>
                      <td>{formatMoney(item[4])}</td>
                      <td>{time}</td>
                    </tr>
                  );
                }
              })}
            </tbody>
          </table>
        </div>

        {totalEnArray?.map((item, index) => {
          if (item.length == 0) {
            return <div key={index}></div>;
          } else {
            const total = Math.ceil(item.recordsTotal / selectNumber);
            // const pageNumbers = Array.from({ length: total }, (_, index) => index + 1);
            return (
              <div className="numberpage">
                {/* <p >Showing {pageNumber * selectNumber + 1} to {start} of  {item.recordsTotal}</p> */}

                <Page handlePageChange={handlePageChange} total={total}></Page>
              </div>
            );
          }
        })}
      </div>
    </div>
  );
}
export default AllBlock;
