import React from "react";
// import telegram from '../../../assets/telegram.png'
// import facebook from '../../../assets/facebook.png'
// import instagram from '../../../assets/instagram.png'
// import twitter from '../../../assets/twitter.png'
// import reddit from '../../../assets/reddit.png'
import "./footerModule.scss";
function Footer() {
  const contact = [
    {
      link: "https://toearnnow.com/Terms-of-Service.pdf",
      title: "Terms of service",
    },
    {
      link: "https://toearnnow.com/auth/contact",
      title: "Contact us",
    },
    {
      link: "",
      title: "Feedback",
    },
    {
      link: "https://toearnnow.com/auth/policy",
      title: "Privacy Policy",
    },
    {
      link: "",
      title: "Join us",
    },
  ];

  return (
    <div className="footer-container">
      <div className="footer-copyright">
        <p>©2024 ToEarnNow. All Rights Reserved.</p>
      </div>
      <div className="footer-contact">
        <ul>
          {contact?.map((item, index) => {
            return (
              <li key={index}>
                <a href={item.link}>{item.title}</a>
              </li>
            );
          })}
        </ul>
      </div>
    </div>
  );
}

export default Footer;
