import TCB from "../Pages/TCB";
import Address from "../Pages/Address";
import AllBlock from "../Pages/AllBlock";
import AllTransaction from "../Pages/AllTransaction";
import Api from "../Pages/Api";
import Blockdesc from "../Pages/Blockdesc";
import Hash from "../Pages/Hash";
import routesConfig from "../config/routes";
import Top from "../Pages/Top100";
import Network from "../Pages/Network";
import NotFound from "../Pages/NotFound";

const publicRoutes = [
  { path: routesConfig.home, component: TCB },
  { path: routesConfig.blocks, component: AllBlock },
  { path: routesConfig.transactions, component: AllTransaction },
  { path: routesConfig.blockdesc, component: Blockdesc },
  { path: routesConfig.hashdesc, component: Hash },
  { path: routesConfig.address, component: Address },
  { path: routesConfig.api, component: Api },
  { path: routesConfig.top, component: Top },
  { path: routesConfig.network, component: Network },
  { path: routesConfig.notfound, component: NotFound },
];

export { publicRoutes };
