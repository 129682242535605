import React, { createContext, useState } from "react";
import Swaptobe from "../assets/Logo/swaptobe.png";
import Extobe from "../assets/Logo/Extobe.png";
import Tobemeta from "../assets/Logo/tobemeta.png";
import Tobesonet from "../assets/Logo/tobesonet.png";
import Tobereal from "../assets/Logo/tobereal.png";
import Tobemarket from "../assets/Logo/tobemarket.png";
import Tobecoin from "../assets/Logo/tobecoin.png";
import Tobegame from "../assets/Logo/tobegame.png";
import TobeEcom from "../assets/Logo/tobeecom.png";
import Tobenft from "../assets/Logo/tobenft.png";
import Tobefarm from "../assets/Logo/tobefarm.png";
import Tobescan from "../assets/Logo/tobescan.png";

export const GlobalContext = createContext();

const GlobalStateProvider = ({ children }) => {
  //state
  const [search, setSearch] = useState("");

  // block
  const [allBlock, setAllBlock] = useState([]);
  const [selectNumber, SetSelectNumber] = useState(10);
  const [totalEn, setTotalEn] = useState([]);
  // const [rowsPerPage, setRowsPerPage] = useState(10)
  const [page, setPage] = useState(0);
  const [start, setStart] = useState(0);

  //logo and img
  // const logos = [
  //     {
  //         img: Swaptobe,
  //         link: ''
  //     },
  //     {
  //         img: Extobe,
  //         link: ''
  //     },
  //     {
  //         img: Tobemeta,
  //         link: ''
  //     },
  //     {
  //         img: Tobesonet,
  //         link: ''
  //     },
  //     {
  //         img: Tobereal,
  //         link: ''
  //     },
  //     {
  //         img: Tobemarket,
  //         link: ''
  //     },
  //     {
  //         img: Tobecoin,
  //         link: ''
  //     },
  //     {
  //         img: Tobegame,
  //         link: ''
  //     },
  //     {
  //         img: TobeEcom,
  //         link: ''
  //     },
  //     {
  //         img: Tobenft,
  //         link: ''
  //     },
  //     {
  //         img: Tobefarm,
  //         link: ''
  //     },
  //     {
  //         img: Tobescan,
  //         link: ''
  //     },
  // ]

  const menu = [
    {
      title: "EXPLORER",
      link: "/",
    },
    // {
    //     title:'NETWORK',
    //     link:'/network'
    // },
    // {
    //     title: 'TOP 100',
    //     link: '/richlist'
    // },
    // {
    //     title: 'API',
    //     link: '/api'
    // }
  ];
  return (
    <GlobalContext.Provider
      value={[
        search,
        setSearch,
        menu,
        allBlock,
        setAllBlock,
        selectNumber,
        SetSelectNumber,
        totalEn,
        setTotalEn,
        start,
        setStart,
        page,
        setPage,
      ]}
    >
      {children}
    </GlobalContext.Provider>
  );
};

export default GlobalStateProvider;
