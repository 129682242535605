import React from "react";

const Nodes = (props) =>{
    return (
        <div className="node-container">
                    <div className="table-node">
                        <p>If you have trouble syncing your wallet, add these lines to your coin daemon .conf file and restart the wallet.
                            Typically you can access the config file through QT-wallet menu, Tools &gt; Open Wallet Configuration File
                        </p>
                        <div>
                            <p>Show</p>
                            <select >
                                <option value="10">10</option>
                                <option value="25">25</option>
                                <option value="50">50</option>
                                <option value="100">100</option>
                            </select>
                            <p>Entries</p>
                        </div>
                        <div>
                            <p>AddNode Config Lines</p>
                            {/* {props.data.length == 0 ? (
                                <table>
                                <thead>
                                    <tr>
                                        <td></td>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        {props.data.map((item,index)=>{
                                            return(
                                                <td key={index}>
                                                    {item}
                                                </td>
                                            )
                                        })}
                                    </tr>
                                </tbody>
                            </table>
                            ):<p>No data available in data</p>} */}
                            
                            <p>No data available in data</p>
                            <div>
                                <p>Showing 0 to 0 of 0 entries</p>
                            </div>
                        </div>
                    </div>
                    
                    <div className="table-node">
                        <p>Alternatively you can try one of these lines in the coin wallet debug window, or add them with coindaemon-cli</p>
                        <div>
                            <p>Show</p>
                            <select >
                                <option value="10">10</option>
                                <option value="25">25</option>
                                <option value="50">50</option>
                                <option value="100">100</option>
                            </select>
                            <p>Entries</p>
                        </div>
                        <div>
                            <p>OneTry Node Lines</p>
                            <p>No data available in table</p>
                            <table>
                                <thead></thead>
                                <tbody></tbody>
                            </table>
                            <div>
                                <p>Showing 0 to 0 of 0 entries</p>
                            </div>
                        </div>
                    </div>
                </div>
    )
}

export default Nodes;