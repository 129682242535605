import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { publicRoutes } from "./Routes";
import "./App.css";
import Footer from "./Component/Layouts/Footer";
import InfoCoin from "./Component/Layouts/Body/InforCoin";
import HeaderTobe from "./Component/Layouts/Header/HeaderTobeScan";
import Logo from "./assets/images/nowcoin.png";
import LogoNow from "./assets/icons/NowCoin.png";
import NotFound from "./Pages/NotFound";
function App() {
  const coin = [
    {
      id: "1",
      logo: LogoNow,
      name: "NOW",
      price: "$0.0085",
      marketPrice: "$9,233,345.00",
      transactions: "34,234",
      lastestBlock: "13234",
      moreInfoCoin: {
        network: "37.237",
        difficulty: "0.00021",
        coinSupply: "33,443",
      },
    },
  ];
  return (
    <div className="sum">
      <HeaderTobe title="NOW Blockchain Explorer" logo={Logo} />
      <div className="body">
        {/* <InfoCoin coin={coin} /> */}
        <Router>
          <Routes>
            {publicRoutes?.map((route, index) => {
              if (route.path) {
                const Page = route.component;
                return (
                  <Route
                    key={index}
                    path={route.path}
                    element={<Page />}
                  ></Route>
                );
              }
            })}
          </Routes>
        </Router>
      </div>

      <Footer />
    </div>
  );
}

export default App;
