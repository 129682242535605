import React, { useState, useEffect } from "react";

import { Link, useParams } from "react-router-dom";
import "./BlockdescModule.scss";
// import { hexToDecimal } from '../../ulti/hextode'
import { convertTimestampToDate } from "../../ulti/timestamp";

import request from "../../ulti/request";
import { formatMoney } from "../../ulti/changeNumbalance";

function Blockdesc() {
  const txId = useParams();
  const [blockId, setBlockId] = useState([]);
  const [amount, setAmount] = useState([]);
  const [listHash, setListHash] = useState([]);

  const mapArray = [blockId];

  useEffect(() => {
    function apiblocks() {
      request
        .get(`/api/getblock?hash=${txId.txid}`)
        .then((response) => {
          const txlist = response.data.tx;
          setListHash(txlist);
          const blocks = response.data;

          setBlockId(blocks);

          const getAmountall = txlist?.map((tx) => {
            return request.get(`/api/getrawtransaction?txid=${tx}&decrypt=1`);
          });

          Promise.all(getAmountall)
            .then((response) => {
              const amounts = response?.map((response) => response.data);
              return amounts;
            })
            .then((amount) => {
              setAmount(amount);
            });
        })
        .catch((error) => {
          console.log(error);
        });
    }
    apiblocks();
  }, []);
  return (
    <div>
      <div className="">
        <div className="body">
          <div className="block-container">
            {mapArray?.map((item, index) => {
              if (item.length === 0) {
                return <p key={index}>Loading...</p>;
              } else {
                return (
                  <div className="block" key={index}>
                    <h3>
                      {item.previousblockhash && (
                        <span>
                          <a href={`/block/${item.previousblockhash}`}>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              height="1em"
                              viewBox="0 0 320 512"
                              style={{ fill: "#ffffff" }}
                            >
                              <path d="M41.4 233.4c-12.5 12.5-12.5 32.8 0 45.3l160 160c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L109.3 256 246.6 118.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0l-160 160z" />
                            </svg>
                          </a>
                        </span>
                      )}
                      NOW block:
                      <span>{item.hash}</span>
                      {item.nextblockhash && (
                        <span>
                          <Link to={`/block/${item.nextblockhash}`}>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              height="1em"
                              viewBox="0 0 320 512"
                              style={{ fill: "#ffffff" }}
                            >
                              <path d="M278.6 233.4c12.5 12.5 12.5 32.8 0 45.3l-160 160c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3L210.7 256 73.4 118.6c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0l160 160z" />
                            </svg>
                          </Link>
                        </span>
                      )}
                    </h3>
                    <table className="table-block">
                      <thead>
                        <tr>
                          <th>Height</th>
                          <th>Difficulty</th>
                          <th>Confirmations</th>
                          <th>Vote</th>
                          <th>Size (kB)</th>
                          <th>Bits</th>
                          <th>Nonce</th>
                          <th>Timestamp</th>
                        </tr>
                      </thead>
                      <tbody>
                        {item.time && (
                          <tr>
                            <td>{item.height}</td>
                            <td>{item.difficulty}</td>
                            <td>{item.confirmations}</td>
                            <td></td>
                            <td>{item.size / 1000}</td>
                            <td>{item.bits}</td>
                            <td>{item.nonce}</td>
                            <td>{convertTimestampToDate(item.time)}</td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                    {!item.time && <strong>Confirming...</strong>}
                  </div>
                );
              }
            })}
            <div className="block trans">
              <h3>Latest Transactions</h3>
              <table>
                <thead>
                  <tr>
                    <th>hash</th>
                    <th>Recipients</th>
                    <th>Amount (NOW)</th>
                  </tr>
                </thead>
                <tbody>
                  {amount?.map((item, index) => {
                    console.log(item);
                    return (
                      <tr key={index}>
                        <td>
                          <Link to={`/tx/${item.txid}`}>{item.txid}</Link>
                        </td>
                        <td>{item.vout.length}</td>
                        <td>
                          {item.vout.length > 1
                            ? formatMoney(
                                item.vout[0].value + item.vout[1].value
                              )
                            : formatMoney(item.vout[0].value)}
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Blockdesc;
