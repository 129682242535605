import React, { useState, useEffect } from "react";
import "./infoCoinModule.scss";
import Block from "../../../../assets/icons/NowBlock.png";
import Market from "../../../../assets/icons/NowMarket.png";
import Transaction from "../../../../assets/icons/NowTrans.png";
import request from "../../../../ulti/request";
import currency from "currency.js";
import { formatMoney } from "../../../../ulti/formatMoney";
import axios from "axios";
// import {MyContext} from '../../../../Context/GlobalState'
function InfoCoin(props) {
  const [summary, setSummary] = useState([]);
  const [totalCoin, setTotalCoin] = useState();
  const [difficulty, setDifficulty] = useState();

  const [dataPrice, setDataPrice] = useState({
    BTC: 0,
    NOWC: 0,
  });
  const [alltx, setAlltx] = useState();
  const getApiSummary = () => {
    request
      .get("ext/summary")
      .then((res) => {
        setSummary(res.data.data);
      })
      .catch((res) => {
        console.log(res);
      });
  };

  const getApiInfoCoin = () => {
    request
      .get("api/getblockcount")
      .then((res) => {
        setTotalCoin(res.data);
      })
      .catch((res) => {
        console.log(res);
      });
  };

  const getNowCoinAndBtc = () => {
    axios
      .get(`https://swzapinewwalletsds.toearnnow.com/api/v1/common/master/coin`)
      .then((res) => {
        const detailBTC = res?.data?.data?.find((item) => item?.name === "BTC");
        const detailNOWC = res?.data?.data?.find(
          (item) => item?.name === "NOWC"
        );
        setDataPrice({
          BTC: detailBTC?.price,
          NOWC: detailNOWC?.set_price,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getalltx = () => {
    request
      .get("ext/getalltx")
      .then((res) => {
        setAlltx(res.data.result);
      })
      .catch((res) => {
        console.log(res);
      });
  };
  const getDifficulty = () => {
    request
      .get("earning/difficulty")
      .then((res) => {
        setDifficulty(res.data.difficulty || 0);
      })
      .catch((res) => {
        console.log(res);
      });
  };

  useEffect(() => {
    getApiSummary();
    getApiInfoCoin();
    getNowCoinAndBtc();
    getalltx();
    getDifficulty();
    const interval = setInterval(() => {
      getApiInfoCoin();
      getalltx();
    }, 60000); // Định kỳ cập nhật mỗi 30 giây

    // Hủy interval khi component bị hủy
    return () => {
      clearInterval(interval);
    };
  }, []);

  return (
    <div className="coin-container">
      {props.coin?.map((item, index) => {
        return (
          <div className="info-coin" key={index}>
            <div className="logo-coin">
              <img src={item.logo} alt=""></img>
            </div>
            <div className="content-coin">
              <div>
                <img style={{ minWidth: "40px" }} src={item.logo} alt=""></img>
                <div>
                  <h4>{item.name} PRICE</h4>
                  <h5>${dataPrice?.NOWC}</h5>
                  <p style={{ minWidth: "160px" }}>
                    @{" "}
                    {dataPrice?.BTC
                      ? (dataPrice?.NOWC / dataPrice?.BTC)
                          .toFixed(8)
                          .toLocaleString()
                      : 0}{" "}
                    BTC
                  </p>
                </div>
              </div>

              <div>
                <img src={Market} alt=""></img>
                <div>
                  <h4>{item.name} MARKETCAP</h4>
                  <p>$9,233,345.00</p>
                </div>
              </div>

              <div>
                <img src={Transaction} alt=""></img>
                <div>
                  <h4>TRANSACTIONS</h4>
                  <p>{alltx ? alltx : 0}</p>
                </div>
              </div>

              <div>
                <img src={Block} alt=""></img>
                <div>
                  <h4>LATEST BLOCK</h4>
                  <p>{totalCoin ? totalCoin : 0} Blocks</p>
                </div>
              </div>
            </div>
          </div>
        );
      })}
      <div className="more-info">
        {summary.length > 0 ? (
          summary?.map((item, index) => {
            return (
              <div key={index}>
                <div>
                  <p>
                    Network (KH/s): <span>{item.hashrate}</span>
                  </p>
                  <p>
                    Difficulty: <span>{difficulty}</span>
                  </p>
                </div>
                <div>
                  <p>
                    Coin Supply: <span>{formatMoney(String(item.supply))}</span>
                  </p>
                  <p>
                    Med gas price: <span>3 Gwei ($0.001)</span>
                  </p>
                </div>
              </div>
            );
          })
        ) : (
          <div>
            <p>Network (KH/s):</p>
            <p>Difficulty: </p>
            <p>Coin Supply:</p>
            <p>Med gas price:</p>
          </div>
        )}
      </div>
    </div>
  );
}

export default InfoCoin;
