import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
// import LogoUser from '../../assets/logouser.png'
import "../AllBlock/allBlockModule.scss";

import request from "../../ulti/request";
import Page from "../../Component/Page";
import { formatCash } from "../../ulti/formatNumber";
import { ChangeNumBalance, formatMoney } from "../../ulti/changeNumbalance";

function AllTransaction() {
  const [allTrans, setAllTrans] = useState([]);
  const [selectNumber, SetSelectNumber] = useState(10);
  const [totalEn, setTotalEn] = useState([]);
  const [draw, setDraw] = useState(0);
  const [pageNumber, setPageNumber] = useState(0);
  const [start, setStart] = useState(0);
  // Xử lý sự kiện thay đổi số lượng
  const handleQuantityChange = (event) => {
    const newQuantity = parseInt(event.target.value, 10);
    SetSelectNumber(newQuantity);
  };

  const handlePageChange = (page) => {
    getApiAllTrans(page.selected * selectNumber);
    setDraw(draw + 1);
    setPageNumber(page.selected);
    setStart(start + selectNumber);
  };

  const getApiAllTrans = (page) => {
    request
      .get(
        `ext/getlasttxsajax/100?draw=1&columns%5B0%5D%5Bdata%5D=0&columns%5B0%5D%5Bname%5D=&columns%5B0%5D%5Bsearchable%5D=true&columns%5B0%5D%5Borderable%5D=false&columns%5B0%5D%5Bsearch%5D%5Bvalue%5D=&columns%5B0%5D%5Bsearch%5D%5Bregex%5D=false&columns%5B1%5D%5Bdata%5D=1&columns%5B1%5D%5Bname%5D=&columns%5B1%5D%5Bsearchable%5D=true&columns%5B1%5D%5Borderable%5D=false&columns%5B1%5D%5Bsearch%5D%5Bvalue%5D=&columns%5B1%5D%5Bsearch%5D%5Bregex%5D=false&columns%5B2%5D%5Bdata%5D=2&columns%5B2%5D%5Bname%5D=&columns%5B2%5D%5Bsearchable%5D=true&columns%5B2%5D%5Borderable%5D=false&columns%5B2%5D%5Bsearch%5D%5Bvalue%5D=&columns%5B2%5D%5Bsearch%5D%5Bregex%5D=false&start=${page}&length=${selectNumber}&search%5Bvalue%5D=&search%5Bregex%5D=false&_=1691133168768`
      )
      .then((res) => {
        const result = res.data.data;
        const total = res.data;
        setAllTrans(result);
        setTotalEn(total);
      })
      .catch((res) => {
        console.log(res);
      });
  };
  const totalEnArray = [totalEn];
  useEffect(() => {
    getApiAllTrans();
  }, [selectNumber]);

  const allTransArray = allTrans;

  return (
    <div className="allblock-container">
      <h5>Latest Movement</h5>
      <div className="block-table">
        <div>
          <p>Show</p>
          <select value={selectNumber} onChange={handleQuantityChange}>
            <option value="10">10</option>
            <option value="25">25</option>
            <option value="50">50</option>
            <option value="100">100</option>
          </select>
          <p>Entries</p>
        </div>

        <table>
          <thead>
            <tr>
              <th>txid</th>
              <th>Amount (NOW)</th>
              <th>Timestamp</th>
            </tr>
          </thead>
          <tbody>
            {allTransArray?.map((item, index) => {
              if (item.length === 0) {
                return <div></div>;
              } else {
                const txid = item[2];
                const amount = item[4];
                const time = item[5];

                return (
                  <tr>
                    <td className="alltrans-txid">
                      <Link to={`/tx/${txid}`}>{txid}</Link>
                    </td>
                    <td>{formatMoney(amount)}</td>
                    <td>{time === 0 ? "loading" : time}</td>
                  </tr>
                );
              }
            })}
          </tbody>
        </table>
      </div>
      {totalEnArray?.map((item, index) => {
        if (item.length === 0) {
          return <div></div>;
        } else {
          const total = Math.ceil(item.recordsTotal / selectNumber);
          return (
            <div className="numberpage">
              {/* <p >Showing 1 to {allTrans.length} of {item.recordsTotal} Entries</p> */}

              <Page handlePageChange={handlePageChange} total={total}></Page>
            </div>
          );
        }
      })}
    </div>
  );
}

export default AllTransaction;
