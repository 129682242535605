export const formatMoney = (money) => {
  let value = "";
  let dotIndex = money.indexOf(".");
  let length = money.length;

  if (dotIndex !== -1) {
    length = dotIndex;
    value = value + money.slice(dotIndex);
  }

  for (let index = 1; index <= length; index++) {
    if (index % 3 === 1 && index > 1)
      value = `${money[length - index]},` + value;
    else value = money[length - index] + value;
  }

  return value;
};
